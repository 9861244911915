import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  useRoutes,
  BrowserRouter as Router,
} from 'react-router-dom';
import Main from './components/Main/Main';
import Navbar from './components/Navbar/Navbar';
import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { animated, useSpring, useTrail } from '@react-spring/web';
import { MeshGradientRenderer } from "@johnn-e/react-mesh-gradient";
import styles1 from './styles.module.css'
import useMeasure from 'react-use-measure'

function AppRoutes(props: any) {


  const routes = useRoutes(
    [
      { path: '/', element: <Main /> },
    ]
  )
  return routes;
}


const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',

    ].join(','),
  },
})

/* #FEC957 0%, #E27520 100%
 */function App() {


  const fast = { tension: 1200, friction: 40 }
  const slow = { mass: 10, tension: 200, friction: 50 }
  const trans = (x: number, y: number) =>
    `translate3d(${x}px,${y}px,0) translate3d(-100%,-50%,0)`

  const [trail, api] = useTrail(3, i => ({
    xy: [0, 0],
    config: i === 0 ? fast : slow,
  }))
  const [ref, { left, top }] = useMeasure()

  const handleMouseMove = (e: any) => {
    api.start({ xy: [e.clientX - left, e.clientY - top] })
  }


  const styles = useSpring({
    config: { duration: 2000 },
    loop: { reverse: true },
    from: {
      background: `linear-gradient(146deg, rgba(255,255,255,1) 0%, rgba(13,51,233,1) 69%)`
    },

    to: {
      background: `linear-gradient(146deg, rgba(127,13,233,1) 0%, rgba(255,255,255,1) 69%)`
    }
  });

  /*   const styles = useSpring({
      config: { duration: 5000 },
      loop: { reverse: true },
      from: {
        background: `linear-gradient(146deg, #FEC957  0%, #FEC957 69%)`
      },
  
      to: {
        background: `linear-gradient(146deg, #E27520 0%, #E27520 69%)`
      }
    });
     */
  return (<ThemeProvider theme={theme}>
    {/*  <div className="ocean">
    */}

    <div className="App">
      <div className={styles1.container}>
        <svg style={{ position: 'absolute', width: 0, height: 0 }}>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="30" />

          </filter>
        </svg>
        {/*       <div ref={ref} className={styles1.hooksMain} onMouseMove={handleMouseMove}>
        {trail.map((props, index) => (
          <animated.div key={index} style={{ transform: props.xy.to(trans) }} />
        ))}
      </div> */}
      </div>
  

   {/*    <Container maxWidth="lg" style={{ height: '100%' }}> */}
        <div className="App">
          <animated.div className="animated-background" style={{ ...styles }} >
            {/*  <div className="ocean">
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
    </div>  */}
            <Router>
              <Navbar></Navbar>
              <Box >
                <AppRoutes></AppRoutes>
              </Box>


            </Router>
          </animated.div>
        </div>
      {/* </Container> */}


    </div>
  </ThemeProvider>
  );
}

export default App;
