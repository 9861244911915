import { Box, Button, Container, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import "./styles.css"
import { isDesktop } from 'react-device-detect';
import WpMainTitle from '../../svgs/WpMainTitle';
import FeaturedPost from './FeaturedPost';
import styles from './styles.module.css'
import { animated, useScroll, useSpring, useTransition } from '@react-spring/web';
import { ContainerDiv, Message, Content, Life } from './styles'
import { getImageSize, useImageSize } from 'react-image-size';
let id = 0

interface MessageHubProps {
  config?: {
    tension: number
    friction: number
    precision: number
  }
  timeout?: number
  children: (add: AddFunction) => void
}

type AddFunction = (msg: string) => void

interface Item {
  key: number
  msg: string
}

async function fetchImageSize(path:string) {
  try {
    debugger 
      const dimensions = await getImageSize(path);
      return dimensions
  } catch (error) {
      console.error(error);
  }
}

function MessageHub({
  config = { tension: 125, friction: 20, precision: 0.1 },
  timeout = 2500,
  children,
}: MessageHubProps) {
  const refMap = useMemo(() => new WeakMap(), [])
  const cancelMap = useMemo(() => new WeakMap(), [])
  const [items, setItems] = useState<Item[]>([])

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0, life: '100%' },
    keys: item => item.key,
    enter: item => async (next, cancel) => {
      debugger
      var a = await fetchImageSize((item as any).msg);
      cancelMap.set(item, cancel)
      await next({ opacity: 1, height: a?.height  /* refMap.get(item).offsetHeight  */})
      await next({ life: '  0%' })
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    onRest: (result, ctrl, item) => {

debugger
      setItems(state =>
        state.filter(i => {
          return i.key !== item.key
        })
      )
    },
    config: (item, index, phase) => key => phase === 'enter' && key === 'life' ? { duration: timeout } : config,
  })

  useEffect(() => {

    children((msg: any) => {
      setItems(state => [...state, { key: id++, msg }])
    })
  }, [])

  return (
    <ContainerDiv>
      {transitions(({ life, ...style }, item) => (
        <Message style={style}>
          <Content ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)} style={{height:'100%'}}>
            {/* <Life style={{ right: life }} /> */}
            <img src={item.msg} style={{ maxHeight:'100%'}} width='100%' />

            {/*   <p>{item.msg}</p> */}
            {/*  <Button
              onClick={(e: MouseEvent) => {
                e.stopPropagation()
                if (cancelMap.has(item) && life.get() !== '0%') cancelMap.get(item)()
              }}>
              <X size={18} />
            </Button> */}
          </Content>
        </Message>
      ))}
    </ContainerDiv>
  )
}
export default function Main() {

  const MINUTE_MS = 2000;


  var res = [
    "1.png",
    "2.png",
    "3.png"

  ]
  const [mytime, setMytime] = useState(0);

  useEffect(() => {

    var c = 0
    const interval = setInterval(() => {

      ref.current?.(res[mytime])

      setMytime((prevTime) => prevTime == 2 ? 0 : prevTime + 1);


    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [mytime])


  var onHelpClick = () => {
    window.open("https://t.me/whypnbot", "_blank")
  }
  const sidebar = {
    title: 'About',
    description:
      'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
    archives: [
      { title: 'March 2020', url: '#' },
      { title: 'February 2020', url: '#' },
      { title: 'January 2020', url: '#' },
      { title: 'November 1999', url: '#' },
      { title: 'October 1999', url: '#' },
      { title: 'September 1999', url: '#' },
      { title: 'August 1999', url: '#' },
      { title: 'July 1999', url: '#' },
      { title: 'June 1999', url: '#' },
      { title: 'May 1999', url: '#' },
      { title: 'April 1999', url: '#' },
    ],
    social: [
      { name: 'GitHub' },
      { name: 'X' },
      { name: 'Facebook' },
    ],
  };

  var social = [
    { name: 'GitHub' },
    { name: 'X' },
    { name: 'Facebook' },
  ]
  var archives = [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ]

  const [state, toggle] = useState(true)
  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 2000 },
  })

  const ref = useRef<null | AddFunction>(null)
  const [scrollVal, setScrollVal] = React.useState(0);


  const { scrollYProgress } = useScroll({
    onChange: ({ value: { scrollYProgress } }) => {
      setScrollVal(scrollYProgress + 1);
    }
  });

  return (
    <Container maxWidth="lg"  style={{
      display:'flex',
      alignItems:'center',
      height:'90VH'
    }}>

      {/* <div style={{ height: "100px" }}>
      <animated.div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: `scale(${scrollVal}) translate(-50%, -50%)`,
          background: "red",
          height: "100px",
          width: "100px"
        }}
      ></animated.div>
      </div> */}
      <div className='image'>


        <div className={"main__sub"} style={{ margin: "auto", textAlign: 'left' }}>
          {
            isDesktop
              ?
              <Container maxWidth="lg" >
                <WpMainTitle></WpMainTitle>
                <Typography sx={{ mt: 1 }} variant="h6" fontWeight={300} color={"white"} className='main__subname' gutterBottom>твой Интернет-щит</Typography>
                <Button onClick={onHelpClick} sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
              </Container>
              :
              <Container maxWidth="lg" >
                <Typography variant="h1" fontWeight={900} color={"white"} className='main__name' gutterBottom>WhyPN</Typography>
                <Typography sx={{ mt: 1 }} variant="h6" fontWeight={300} color={"white"} className='main__subname' gutterBottom>твой Интернет-щит</Typography>
                <Button onClick={onHelpClick} sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
              </Container>
          }

        </div>

      </div>

    {/*   <div style={{position:'absolute', right: 0, top:"13%"}}>
          <MessageHub
            children={(add: AddFunction) => {
              ref.current = add
            }}
          />
        </div> */}
    </Container>

    /*     <Container maxWidth="lg">
          
    
        </Container > */
    /* <div className={"main"}>
        <div className={"main__sub"} style={{ margin: "auto", textAlign: 'left' }}>
            {
                isDesktop
                    ?
                    <Container maxWidth="lg" >
                        <WpMainTitle></WpMainTitle>
                        <Typography sx={{ mt: 1 }} variant="h6" fontWeight={300} color={"white"} className='main__subname' gutterBottom>твой Интернет-щит</Typography>
                        <Button onClick={onHelpClick} sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
                    </Container>
                    :
                    <Container maxWidth="lg" >
                        <Typography variant="h1" fontWeight={900} color={"white"} className='main__name' gutterBottom>WhyPN</Typography>
                        <Typography sx={{ mt: 1 }} variant="h6" fontWeight={300} color={"white"} className='main__subname' gutterBottom>твой Интернет-щит</Typography>
                        <Button onClick={onHelpClick} sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
                    </Container>
            }
 
        </div>
    </div> */
  );
}


